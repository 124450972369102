<template>
  <div class="CommunicationForm-wrapper">
    <form-panel ref="formPanel"
                v-bind="submitConfig"
                :form="form"
                :submitBefore="submitBefore"
                @update="update">
      <col2-detail>
        <col2-block title="基本信息">
          <template>
            <el-form-item label="姓名"
                          :rules="[{ required: true, message: '请输入姓名', trigger: 'blur' }]"
                          prop="userName">
              <v-input v-model="form.userName"
                       placeholder="请输入姓名"
                       :width="width"
                       :maxlength="10" />
            </el-form-item>
            <el-form-item label="身份"
                          :rules="[{ required: true, message: '请输入姓名', trigger: 'blur' }]"
                          prop="identity">
              <v-input v-model="form.identity"
                       placeholder="身份描述，30字以内"
                       :width="width"
                       :maxlength="30" />
            </el-form-item>
            <el-form-item label="手机号"
                          :rules="[
                          { required: true, message: '请输入手机号', trigger: 'blur' },
                          { validator: validatePhone, trigger: 'blur' }]"
                          prop="mobileNum">
              <v-input v-model="form.mobileNum"
                       placeholder="请输入手机号"
                       :width="width" />
            </el-form-item>
            <el-form-item label="角色类别"
                          :rules="[{ required: true, message: '请选择组织级别', trigger: 'change' }]"
                          prop="userType">
              <v-select v-model="form.userType"
                        :options="roleTypeOps"
                        :width="width" />
            </el-form-item>
            <el-form-item label="角色归属"
                          :rules="[{ required: true, message: '请选择所属组织', trigger: 'change' }]"
                          prop="orgId">
              <v-select2 v-model="form.orgId"
                         v-bind="orgParams"
                         :width="width"
                         :subjoin="{ roleType: form.userType }"></v-select2>
            </el-form-item>
          </template>
          <el-form-item label="状态"
                        :rules="[{ required: true, message: '请选择状态', trigger: 'change' }]"
                        prop="status">
            <v-select v-model="form.status"
                      :options="statusOps"
                      :width="width" />
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import { saveOutURL, updateOutURL, queryURL, getOrgURL } from './api'
import { setStatusOps, setRoleTypeOps } from './map'
import { Col2Detail, Col2Block } from 'components/bussiness'
import { mobileRegular } from 'common/regular'

export default {
  name: 'CommunicationForm',
  components: {
    Col2Detail,
    Col2Block
  },
  data () {
    return {
      width: 182,
      statusOps: setStatusOps(),
      roleTypeOps: setRoleTypeOps(),
      form: {
        userName: '',
        mobileNum: '',
        userType: 100,
        status: 1,
        identity: '',
        orgId: ''
      },
      submitConfig: {
        queryUrl: queryURL,
        submitUrl: saveOutURL,
        submitContentType: 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      orgParams: {
        searchUrl: getOrgURL,
        request: {
          text: 'orgName_',
          value: 'orgId_'
        }
      },
      source: 0
    }
  },
  computed: {
    isEdit () {
      return this.$route.query.id
    },
    userType () {
      return this.$store.state.userInfo.userType
    }
  },
  mounted () {
    const { id } = this.$route.query
    if (id !== undefined) {
      this.$setBreadcrumb('编辑')
      this.submitConfig.submitUrl = updateOutURL
      this.$refs.formPanel.getData({ id })
    } else {
      this.$setBreadcrumb('新增')
      this.submitConfig.submitUrl = saveOutURL
    }
    if (this.userType === '101') {
      this.roleTypeOps = this.roleTypeOps.slice(2)
      this.form.userType = this.roleTypeOps[0].value
    } else if (this.userType === '102') {
      this.roleTypeOps = this.roleTypeOps.slice(1)
      this.form.userType = this.roleTypeOps[0].value
    }
  },
  methods: {
    update (data) {
      Object.keys(this.form).forEach(key => {
        this.form[key] = data[key]
      })
    },
    submitBefore (data) {
      if (this.isEdit) {
        data.id = this.$route.query.id
      }
      return true
    },
    validatePhone (rule, value, callback) {
      let regExp = mobileRegular
      if (!regExp.test(value)) {
        callback(new Error('手机号码格式不正确'))
      } else {
        callback()
      }
    }
  }
}
</script>
