var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "CommunicationForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "基本信息" } },
                [
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "姓名",
                          rules: [
                            {
                              required: true,
                              message: "请输入姓名",
                              trigger: "blur",
                            },
                          ],
                          prop: "userName",
                        },
                      },
                      [
                        _c("v-input", {
                          attrs: {
                            placeholder: "请输入姓名",
                            width: _vm.width,
                            maxlength: 10,
                          },
                          model: {
                            value: _vm.form.userName,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "userName", $$v)
                            },
                            expression: "form.userName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "身份",
                          rules: [
                            {
                              required: true,
                              message: "请输入姓名",
                              trigger: "blur",
                            },
                          ],
                          prop: "identity",
                        },
                      },
                      [
                        _c("v-input", {
                          attrs: {
                            placeholder: "身份描述，30字以内",
                            width: _vm.width,
                            maxlength: 30,
                          },
                          model: {
                            value: _vm.form.identity,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "identity", $$v)
                            },
                            expression: "form.identity",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "手机号",
                          rules: [
                            {
                              required: true,
                              message: "请输入手机号",
                              trigger: "blur",
                            },
                            { validator: _vm.validatePhone, trigger: "blur" },
                          ],
                          prop: "mobileNum",
                        },
                      },
                      [
                        _c("v-input", {
                          attrs: {
                            placeholder: "请输入手机号",
                            width: _vm.width,
                          },
                          model: {
                            value: _vm.form.mobileNum,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "mobileNum", $$v)
                            },
                            expression: "form.mobileNum",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "角色类别",
                          rules: [
                            {
                              required: true,
                              message: "请选择组织级别",
                              trigger: "change",
                            },
                          ],
                          prop: "userType",
                        },
                      },
                      [
                        _c("v-select", {
                          attrs: { options: _vm.roleTypeOps, width: _vm.width },
                          model: {
                            value: _vm.form.userType,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "userType", $$v)
                            },
                            expression: "form.userType",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "角色归属",
                          rules: [
                            {
                              required: true,
                              message: "请选择所属组织",
                              trigger: "change",
                            },
                          ],
                          prop: "orgId",
                        },
                      },
                      [
                        _c(
                          "v-select2",
                          _vm._b(
                            {
                              attrs: {
                                width: _vm.width,
                                subjoin: { roleType: _vm.form.userType },
                              },
                              model: {
                                value: _vm.form.orgId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "orgId", $$v)
                                },
                                expression: "form.orgId",
                              },
                            },
                            "v-select2",
                            _vm.orgParams,
                            false
                          )
                        ),
                      ],
                      1
                    ),
                  ],
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "状态",
                        rules: [
                          {
                            required: true,
                            message: "请选择状态",
                            trigger: "change",
                          },
                        ],
                        prop: "status",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.statusOps, width: _vm.width },
                        model: {
                          value: _vm.form.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "status", $$v)
                          },
                          expression: "form.status",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }